import { Loadable } from 'src/common';

import { PATH } from 'src/constants';

const BalancesPage = Loadable(() => import('./pages/Balances'));
const PaymentsPage = Loadable(() => import('./pages/Payments'));
// TODO: TEMP HIDE - DT#16-JAN-25
// const RequestPaymentPage = Loadable(() => import('./pages/RequestPayment'));
const VideoEarningsPage = Loadable(() => import('./pages/VideoEarnings'));
const AudioEarningsPage = Loadable(() => import('./pages/AudioEarnings'));

export default [
  {
    component: BalancesPage,
    path: `${PATH.FINANCIAL}/balances`,
    name: 'Balances',
    exact: true,
  },
  {
    component: PaymentsPage,
    path: `${PATH.FINANCIAL}/payments`,
    name: 'Payments',
  },
  // TODO: TEMP HIDE - DT#16-JAN-25 {
  //   component: RequestPaymentPage,
  //   path: `${PATH.FINANCIAL}/request-payment`,
  //   name: 'Request Payments',
  // },
  {
    component: VideoEarningsPage,
    path: `${PATH.FINANCIAL_EARNINGS}/video-earnings`,
    name: 'Video Earnings',
  },
  {
    component: AudioEarningsPage,
    path: `${PATH.FINANCIAL_EARNINGS}/audio-earnings`,
    name: 'Audio Earnings',
  },
];
